<template>
  <div style="background-color:#FFF;">
    <div class="zc-ceil">
      <div class="py-container">
        <ul>
          <li>
            江苏泽超信息技术有限公司欢迎您
            <template v-if="user">
              {{ user.phone }} 用户，
              <a @click="onLogout">退出登录</a>
            </template>
            <a v-else @click="$router.push({ path: '/user/login' })">请登录</a>
          </li>
          <li class="notice-left" v-if="noticeList.length">公告：</li>
          <li class="notice0">
            <div class="notice1" :style="{ transform: `translateY(-${noticeIndex * 20}px)`, transition: noticeIndex !== 0 ? `all 0.5s linear` : '' }" @click="$router.push('/product/notice')">
              <div class="notice2" v-for="(item, index) in noticeList" :key="index" :style="{ transform: `translateY(${index * 20}px)` }">{{ item.title }}</div>
            </div>
          </li>
        </ul>
        <ul class="zc-ceil-menu">
          <li><a @click="$router.push({ path: '/user/index' })">我的账户</a></li>
          <li><a @click="$router.push({ path: '/user/order/all' })">我的订单</a></li>
          <li><a @click="$router.push({ path: '/user/favorite' })">我的收藏</a></li>
          <li><a @click="$router.push({ path: '/user/address' })">我的地址</a></li>
        </ul>
      </div>
    </div>
    <div class="py-container">
      <div class="zc-header">
        <a class="zc-header-logo" @click="$router.push({ path: '/home' })"></a>
        <form class="zc-header-form">
          <div class="zc-header-search">
            <img class="zc-header-icon" :src="require('../../assets/images/search.png')" />
            <input type="text" v-model="keywords" @change="onSearch(keywords)" />
            <button type="button" @click="onSearch(keywords)">搜索</button>
          </div>
          <div class="zc-header-keyword" v-if="hotKeywordList && hotKeywordList.length">
            <a v-for="(item, index) in hotKeywordList" :key="index" @click="onSearch(item.keyword)">{{ item.keyword }}</a>
          </div>
        </form>
        <a class="zc-header-cart" @click="$router.push({ path: '/user/cart' })">
          <img class="zc-header-icon" :src="require('../../assets/images/cart.png')" />
          我的购物车
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonHeader",
  data() {
    return {
      hotKeywordList: [],
      keywords: null,
      noticeList: [],
      noticeIndex: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLogin() {
      return this.$store.getters.isLogin;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.$api.base.SearchIndex().then((res) => {
      this.hotKeywordList = res.hotKeywordList;
    });

    this.$api.base.getArticles().then((res) => {
      this.noticeList = res.articles;
      if (this.noticeList[0]) {
        this.noticeList.push(this.noticeList[0]);
      }
    });

    this.keywords = this.$route.params.keywords?.replace(/,+/gi, " ");
    console.log(this.keywords);

    const loop = () => {
      setTimeout(() => {
        loop();
      }, 3000);
      this.noticeIndex += 1;
      if (this.noticeIndex >= this.noticeList.length) {
        this.noticeIndex = 0;
      }
    };
    loop();
  },
  methods: {
    onSearch(keywords) {
      this.keywords = (keywords || this.keywords || "").trim();

      if (this.keywords) {
        this.$router.push({ path: `/product/search/${this.keywords.trim().split(/[\s|\/]+/gi)}` });
      } else {
        uni.showToast({
          title: "请输入搜索关键字",
        });
      }

      this.$emit("searcher", this.keywords);
    },
    onLogout() {
      this.$store.commit("setLogout");
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.zc-ceil {
  background-color: #f5f5f5;
  line-height: 1;
}

.zc-ceil .py-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.zc-ceil-menu {
  transform: translateX(15px);
}

.zc-ceil-menu li {
  padding: 0 15px;
  border-left: 1px solid #ccc;
}

.zc-ceil-menu li:first-child {
  border-left: none;
}

.zc-ceil ul {
  display: flex;
  list-style: none;
}

.zc-ceil ul li {
}
.zc-header {
  height: 80px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.zc-header-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}

.zc-header-logo {
  display: block;
  background-image: url(../../assets/img/Logo.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 180px;
  height: 100%;
  background-size: contain;
  margin-right: 80px;
}

.zc-header-form {
  flex-grow: 1;
}

.zc-header-search {
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  padding: 2px;
  border: solid 2px #fa5302;
  border-radius: 22px;
  overflow: hidden;
  display: flex;
  padding-left: 20px;
  align-items: center;
}

.zc-header-search input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  flex-shrink: 1;
  flex-grow: 1;
  font-size: 14px;
}

.zc-header-search button {
  border: none;
  outline: none;
  display: block;
  width: 100px;
  height: 100%;
  background: linear-gradient(140deg, #fa5302 0%, #f73602 100%);
  border-radius: 19px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}

.zc-header-keyword {
  margin-left: 23px;
}

.zc-header-keyword span,
.zc-header-keyword a {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 14px;
  color: #b5b6b8;
}

.zc-header-cart {
  width: 160px;
  height: 44px;
  box-sizing: border-box;
  border: solid 2px #fa5302;
  border-radius: 22px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fa5302;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  margin-left: 30px;
}
.notice-left {
  padding-left: 20px;
  color: #f60;
}
.notice0 {
  height: 20px;
  width: 400px;
  overflow: hidden;
  position: relative;
  color: #f60;
  .notice1 {
    .notice2 {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}
</style>
